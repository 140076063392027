/**
 * Robot to external port: A label corresponding to an output port on the IO board
 */
import * as zod from 'zod';

export const OUTPUT_PORT_IDS = [
  'Output 1',
  'Output 2',
  'Output 3',
  'Output 4',
  'Output 5',
  'Output 6',
  'Output 7',
  'Output 8',
  'Output 9',
  'Output 10',
  'Output 11',
  'Output 12',
  'Output 13',
  'Output 14',
  'Output 15',
  'Output 16',
] as const;

export const OUTPUT_PORT_COUNT = OUTPUT_PORT_IDS.length;

export const OutputPortID = zod.enum(OUTPUT_PORT_IDS);

export type OutputPortID = zod.infer<typeof OutputPortID>;
