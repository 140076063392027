import * as zod from 'zod';

import { schunkEGxVariantInfo } from '../utils/schunkEGxVariantInfo';

import { SchunkEGxVariant } from './SchunkEGxVariant';

const defaults = schunkEGxVariantInfo('EGU 60-MB-M-B');

export const SchunkEGxConfiguration = zod.object({
  kind: zod.literal('SchunkEGx'),
  name: zod.string().default('EGU / EGK'),
  variant: SchunkEGxVariant.default(defaults.variant),
  zeroPositionOffset: zod.number().default(defaults.zeroPositionOffset),
  minPosition: zod.number().default(defaults.minPosition),
  maxPosition: zod.number().default(defaults.maxPosition),
  tcpZOffset: zod.number().default(0.05),
});

export type SchunkEGxConfiguration = zod.infer<typeof SchunkEGxConfiguration>;
