import * as zod from 'zod';

export const DHCommand = zod.object({
  targetForce: zod.number().min(0.2).max(1).optional(),
  targetDiameter: zod.number(),
  targetDiameterTolerance: zod.number().optional(),

  targetSpeed: zod.number().min(0).max(1).default(0.6),

  /**
   * This field will fail the actuation if the force grip is not detected
   * after actuating a grip command.
   */
  waitForGripToContinue: zod.boolean().optional(),
});

export type DHCommand = zod.infer<typeof DHCommand>;
