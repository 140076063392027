import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';

import { IntegrationActionKind } from '../../types';
import {
  getAction,
  getDevice,
  getWorkholding,
} from '../toRoutineRunnerHelpers';

export namespace CNCOperateWorkholdingStepDatabase {
  export const name = 'Operate workholding';
  export const description =
    'Operate the vise, chuck or other workholding on a CNC machine';
  export const deviceKinds = ['CNCMachine'] as const;
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const librarySort = '2';

  export const argumentKind = 'CNCOperateWorkholding';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    deviceID: zod.string().default(''),
    workholdingID: zod.number().default(0),
    actionKind: IntegrationActionKind.default('clamp'),
    actionName: zod.string().default('Clamp'),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    equipment,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const machineConfig = getDevice(args.deviceID, equipment);

    const workholding = getWorkholding(machineConfig, args.workholdingID);

    getAction(workholding, args.actionKind, args.actionName);

    return {
      ...stepData,
      stepKind: 'CNCOperateWorkholding',
      args,
    };
  };
}

CNCOperateWorkholdingStepDatabase satisfies Step.StepKindInfo;
