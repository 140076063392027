import { useCallback, useState } from 'react';
import { create } from 'zustand';

import type { SoftwareUpdateData } from '@sb/feathers-types';
import { makeNamespacedLog } from '@sb/log';
import { wait } from '@sb/utilities';
import { getAllJointNumbersExceptIOBoard } from '@sb/utilities/src/joints';
import { useFeatureFlag } from '@sbrc/hooks';
import { getSoftwareUpdateData, requestSoftwareInstall } from '@sbrc/services';

const log = makeNamespacedLog('useSoftwareBuildData');

export interface SoftwareBuildData extends Partial<SoftwareUpdateData> {
  timeChecked: Date | null;
  runningActiveBuildId: string | undefined;
  hasRequestedSoftwareInstall: boolean;
  isInstallingSoftware: boolean;
  onRequestSoftwareInstall: () => void;
  selectedJointsForUpdate: number[];
  setSelectedJointsForUpdate: (selectedJoints: number[]) => void;
  isSoftwareUpdateAvailable: boolean;
}

const INITIAL_BUILD_DATA: SoftwareBuildData = {
  timeChecked: null,
  runningActiveBuildId: undefined,
  hasRequestedSoftwareInstall: false,
  isInstallingSoftware: false,
  onRequestSoftwareInstall: () => {},
  selectedJointsForUpdate: [],
  setSelectedJointsForUpdate: () => {},
  isSoftwareUpdateAvailable: false,
};

const useBuildData = create(() => INITIAL_BUILD_DATA);

/**
 * poll backend for changes in software build data
 */
export const pollSoftwareBuildData = async () => {
  while (true) {
    try {
      const softwareUpdateData = await getSoftwareUpdateData();

      let { runningActiveBuildId, hasRequestedSoftwareInstall } =
        useBuildData.getState();

      if (
        !softwareUpdateData ||
        softwareUpdateData.isSoftwareInstallRequested ||
        softwareUpdateData.status === 'installing' ||
        softwareUpdateData.status === 'installed'
      ) {
        hasRequestedSoftwareInstall = false;
      }

      const activeBuildID =
        softwareUpdateData?.activeBuildID ??
        // if undefined then feathers-bot is running a different version than remote-control
        // (i.e. during upgrade)
        'version-mismatch';

      if (runningActiveBuildId === undefined) {
        runningActiveBuildId = activeBuildID;
      } else if (runningActiveBuildId !== activeBuildID) {
        // reload page if active build changes
        window.location.reload();
      }

      useBuildData.setState({
        ...INITIAL_BUILD_DATA,
        ...softwareUpdateData,
        timeChecked: new Date(),
        runningActiveBuildId,
        hasRequestedSoftwareInstall,
      });

      await wait(1_000);
    } catch (error) {
      log.warn('poll.fail', 'Failed to get SoftwareUpdateData', error);
      await wait(2_000);
    }

    await wait(1_000);
  }
};

export const useSoftwareBuildData = (): SoftwareBuildData => {
  const enableFirmareAutoUpdate = useFeatureFlag('enableFirmwareAutoUpdate');

  const buildData = useBuildData();

  const [selectedJointsForUpdate, setSelectedJointsForUpdate] = useState<
    number[]
  >(getAllJointNumbersExceptIOBoard);

  const onRequestSoftwareInstall = useCallback(async () => {
    useBuildData.setState({ hasRequestedSoftwareInstall: true });

    await requestSoftwareInstall(
      enableFirmareAutoUpdate ? selectedJointsForUpdate : [],
    );
  }, [enableFirmareAutoUpdate, selectedJointsForUpdate]);

  const isInstallingSoftware =
    buildData.hasRequestedSoftwareInstall ||
    buildData.isSoftwareInstallRequested ||
    buildData.status === 'installing';

  const isSoftwareUpdateAvailable: boolean =
    (buildData.status === 'downloaded' || buildData.status === 'installing') &&
    buildData.targetBuildID !== undefined &&
    buildData.targetBuildID !== buildData.activeBuildID;

  return {
    ...buildData,
    onRequestSoftwareInstall,
    selectedJointsForUpdate,
    setSelectedJointsForUpdate,
    isInstallingSoftware,
    isSoftwareUpdateAvailable,
  };
};
