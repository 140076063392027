import * as zod from 'zod';

import {
  createGenericAutodoor,
  createGenericMachineStatus,
  createGenericCycle,
  createGenericWorkholding,
} from './createDefault';
import { HaasNetworkConfig } from './HaasNetwork';
import {
  IntegrationActionGroup,
  IntegrationInterfaceKind,
} from './IntegrationAction';
import { MachineStatusConfig } from './MachineStatus';

export const CNCMachineConfiguration = zod.object({
  kind: zod.literal('CNCMachine'),
  name: zod.string().default('CNC Machine'),
  interfaceKinds: IntegrationInterfaceKind.array().default(['controlBoxIO']),
  haasNetwork: HaasNetworkConfig.default({}),
  cycle: IntegrationActionGroup.default(createGenericCycle()),
  machineStatus: MachineStatusConfig.default(createGenericMachineStatus()),
  autodoor: IntegrationActionGroup.default(createGenericAutodoor()),
  workholdings: IntegrationActionGroup.array().default([
    createGenericWorkholding('Vise', 0),
  ]),
});

export type CNCMachineConfiguration = zod.infer<typeof CNCMachineConfiguration>;
