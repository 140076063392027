import * as zod from 'zod';

import { IntegrationActionKind } from '../../types';

export const CNCOperateAutodoorArguments = zod.object({
  deviceID: zod.string(),
  actionKind: IntegrationActionKind,
  actionName: zod.string(),
});

export type CNCOperateAutodoorArguments = zod.infer<
  typeof CNCOperateAutodoorArguments
>;
