import * as zod from 'zod';

import { IntegrationAction, IntegrationActionKind } from './IntegrationAction';

export const CNCMachineCommand = zod.object({
  kind: zod.literal('CNCMachineCommand'),
  deviceID: zod.string(),
  subCommand: zod.discriminatedUnion('kind', [
    zod.object({
      kind: zod.literal('runProgram'),
      haasProgram: zod.string(),
    }),
    zod.object({
      kind: zod.literal('autodoor'),
      actionKind: IntegrationActionKind,
      actionName: zod.string().optional(),
    }),
    zod.object({
      kind: zod.literal('workholding'),
      workholdingID: zod.number(),
      actionKind: IntegrationActionKind,
      actionName: zod.string().optional(),
    }),
    zod.object({
      kind: zod.literal('haasCellSafe'),
      isEnabled: zod.boolean(),
    }),
    zod.object({
      kind: zod.literal('executeAction'),
      action: IntegrationAction,
    }),
  ]),
});

export type CNCMachineCommand = zod.infer<typeof CNCMachineCommand>;
