/**
 * Robot to external port: A label corresponding to an output port on the IO board
 */
import * as zod from 'zod';

import { OUTPUT_PORT_IDS } from './OutputPort';
import { RELAY_PORT_NAMES } from './RelayPort';

export const OUTPUT_OR_RELAY_PORT_IDS = [
  ...OUTPUT_PORT_IDS,
  ...RELAY_PORT_NAMES,
] as const;

export const OUTPUT_OR_RELAY_PORT_COUNT = OUTPUT_OR_RELAY_PORT_IDS.length;

export const OutputOrRelayPortID = zod.enum(OUTPUT_OR_RELAY_PORT_IDS);

export type OutputOrRelayPortID = zod.infer<typeof OutputOrRelayPortID>;
