import type { DistanceUnit, DistanceUnitInfo } from '@sb/remote-control/types';
import { roundToDecimalPlaces } from '@sb/utilities';

function using({
  decimalPlaces = 4,
  multiplier = 1,
}): Pick<
  DistanceUnitInfo,
  'decimalPlaces' | 'multiplier' | 'fromMeters' | 'toMeters'
> {
  return {
    decimalPlaces,
    multiplier,
    fromMeters: (value, roundingKind) =>
      typeof value === 'number'
        ? roundToDecimalPlaces(value * multiplier, decimalPlaces, roundingKind)
        : value,
    toMeters: (value) =>
      typeof value === 'number' ? value / multiplier : value,
  };
}

export const DISTANCE_UNIT_INFOS: Array<DistanceUnitInfo> = [
  {
    unit: 'meter',
    name: 'Meter',
    abbreviation: 'm',
    decimalPlaces: 4,
    multiplier: 1,
    fromMeters: (v) => v,
    toMeters: (v) => v,
    toString: (v) => `${v} m`,
  },
  {
    unit: 'millimeter',
    name: 'Millimeter',
    abbreviation: 'mm',
    ...using({ decimalPlaces: 1, multiplier: 1000 }),
    toString: (v) => `${v} mm`,
  },
  {
    unit: 'inch',
    name: 'Inch',
    abbreviation: 'inch',
    ...using({ decimalPlaces: 3, multiplier: 39.37007874 }),
    toString: (v) => `${v} inch`,
  },
];

export function getDistanceUnitInfo(unit?: DistanceUnit): DistanceUnitInfo {
  return (
    DISTANCE_UNIT_INFOS.find((info) => info.unit === unit) ??
    DISTANCE_UNIT_INFOS[0]
  );
}
