import * as zod from 'zod';

import { OutputPortID, IOLevel, RelayPort } from '../../types';

const PortID = zod.enum([...OutputPortID.options, ...RelayPort.options]);
export default zod.object({
  changes: zod.array(
    zod.object({
      label: PortID,
      level: IOLevel,
    }),
  ),
});
