import * as zod from 'zod';

import { Step } from '@sb/remote-control/types';

import { IntegrationActionKind } from '../../types';
import { getAction, getDevice } from '../toRoutineRunnerHelpers';

export namespace CNCOperateAutodoorStepDatabase {
  export const name = 'Operate autodoor';
  export const description = 'Operate the autodoor on a CNC machine';
  export const deviceKinds = ['CNCMachine'] as const;
  export const librarySection = Step.LibrarySection.InterfaceWithMachines;
  export const librarySort = '2';

  export const argumentKind = 'CNCOperateAutodoor';

  export const Arguments = zod.object({
    argumentKind: zod.literal(argumentKind),
    deviceID: zod.string().default(''),
    actionKind: IntegrationActionKind.default('open'),
    actionName: zod.string().default('Open'),
  });

  export type Arguments = zod.infer<typeof Arguments>;

  export const toRoutineRunner: Step.ToRoutineRunner = ({
    stepConfiguration: { args },
    stepData,
    equipment,
  }) => {
    if (args?.argumentKind !== argumentKind) {
      throw new TypeError(`Expected argument kind ${argumentKind}`);
    }

    const machineConfig = getDevice(args.deviceID, equipment);

    getAction(machineConfig.autodoor, args.actionKind, args.actionName);

    return {
      ...stepData,
      stepKind: 'CNCOperateAutodoor',
      args,
    };
  };
}

CNCOperateAutodoorStepDatabase satisfies Step.StepKindInfo;
