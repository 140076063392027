import type {
  ArmJointConnectionStates,
  ArmJointPositions,
} from '@sb/motion-planning';
import { JOINT_NUMBERS } from '@sb/motion-planning';
import type {
  InputPortID,
  IOLevel,
  RelayPort,
  OutputPortID,
  SafetyPort,
} from '@sb/routine-runner';
import { RELAY_PORT_COUNT, SAFETY_PORT_COUNT } from '@sb/routine-runner';
import { six, sixteen } from '@sb/utilities';

export const ROBOT_ARM_VELOCITY_PERCENT_MAX = 100;
export const ROBOT_ARM_VELOCITY_PERCENT_MIN = 1;

export const ROBOT_JOINT_ANGLES_DEFAULT: ArmJointPositions = six(0);

export const ROBOT_JOINT_CONNECTION_STATES_DEFAULT: ArmJointConnectionStates =
  six('ready');

export const ROBOT_TOOLTIP_SPEED_DEFAULT = 1;

export const ROBOT_JOINT_ANGLES_BOX: ArmJointPositions = [
  0.8587019919812102, 1.8168877513260968, -2.787290815434944,
  -0.5235987755982988, -3.143337982841787, 1.0925761117484503,
];

export const JOINT_NAMES = JOINT_NUMBERS.map((n: number) => `J${n}`);

export const MOCK_ROBOT_INPUT_IO = sixteen('low' as IOLevel).reduce(
  (obj, level, index) => {
    const newObj = { ...obj };
    newObj[`Input ${index}` as InputPortID] = level;
    newObj[`Output ${index + 1}` as InputPortID] = level;

    if (index < SAFETY_PORT_COUNT) {
      newObj[`Safety ${index + 1}` as SafetyPort] = level;
    }

    if (index < RELAY_PORT_COUNT) {
      newObj[`Relay ${index + 1}` as RelayPort] = level;
    }

    return newObj;
  },
  {} as Record<InputPortID | OutputPortID | RelayPort | SafetyPort, IOLevel>,
);
