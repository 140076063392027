import * as zod from 'zod';

export type Currency = 'USD';

export type Unsubscribe = () => void;

export const DistanceUnit = zod.union([
  zod.literal('meter'),
  zod.literal('millimeter'),
  zod.literal('inch'),
]);

export type DistanceUnit = zod.infer<typeof DistanceUnit>;

export interface DistanceUnitInfo {
  unit: DistanceUnit;
  name: string;
  abbreviation: string;
  decimalPlaces: number;
  multiplier: number;
  fromMeters: <V>(
    value: V,
    roundingKind?: 'round' | 'floor' | 'ceil',
  ) => V | number;
  toMeters: <V>(value: V) => V | number;
  toString: <V>(value: V) => string;
}
